import styled, {css} from 'styled-components/macro';
import OButton from './button';
import { alignCenter, alignStart, column, flex, justifyCenter, wrap } from './css';
import { device } from './mbreakpoints';

const Wrapper = styled.div`

    min-height:100%;
    padding-bottom:48px;
    @media(min-width:768px){
        padding-bottom:0;
    }
`;

const Inner = styled.div`

    width:100%;
    max-width:${p => p.forRead ? `100ch` : `var(--max-width-container)`};
    margin: 0 auto;
    padding-right: var(--padding-regular-x);
    padding-left: var(--padding-regular-x);
    position:relative;
    ${({hero}) => hero && css`
    
        height:100%;
        display:flex;
        align-items:center;
    `};

`;

const Separator = styled.div`
    padding-bottom: ${ p => (p.xL ? 'var(--padding-large-y)' : 'var(--padding-regular-y)') }
`;

const Box = styled.div`
    background-color:var(--brand-primary);
    padding: var(--padding-regular-y) var(--padding-regular-x);
`;

const FlexWrapper = styled.div`
    ${ flex };
    ${ p => p.fWrap && wrap };
    ${ p => p.alignCenter && alignCenter };
    ${ p => p.justifyCenter && justifyCenter };
    ${ p => p.flexStart && alignStart };
    height:${ p => p.fHeight ? p.fHeight : 'auto'};
    flex-direction:${ p => (p.column ? column : 'column') };
    ${({alternative}) => alternative && css`
        @media ${ device.tablet } { 
            background: var(--brand-primary);
            padding: 2rem;
            border-radius: 10px;
        }
    
    `};

`;

const FlexBox = styled.div`
    display:flex;
    flex-wrap: ${ p => p.$wrap ? p.$wrap : 'no-wrap'};
    align-items: ${ p => p.$alignItems ? p.$alignItems : "stretch" };
    justify-content: ${ p => p.$justifyContent ? p.$justifyContent : "stretch" };
    flex-direction:${ p => p.$direction ? p.$direction : 'row' };
`;

const ItemPicture = styled.div`
    flex-shrink:1;
    flex-grow:0;
    min-width:0;
    flex-basis: 100%;
    margin: 20px;
    @media ${ device.tablet } { 
        flex-basis:377px;
        max-width:377px;
      }

    img {
        width:100%;
        object-fit:contain;
    }
`;

const ButtonsWrapper = styled.div`

    display:flex;
    justify-content:center;

`;

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 2.5rem;
    justify-content:${({reverse}) => reverse ? 'flex-start' : 'flex-end'};
    
    ${OButton} {
        flex: 1 1 0%;
        @media ${ device.mobileL } { 
            flex: 0 1 22.7rem;
          }
        
    }
`;

export {
    Inner,
    Separator,
    Box,
    FlexWrapper,
    ItemPicture,
    ButtonsWrapper,
    ButtonWrapper,
    Wrapper,
    FlexBox
};