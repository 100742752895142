// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Grid, Header, Icon, Label, List, Table } from 'semantic-ui-react';

// Components
import BottomNav from '../../components/bottomNav/BottomNav';
import PanelNav from '../../components/nav/PanelNav';
import NumericFormat from '../../components/numericFormat/NumericFormat';

// Styled components
import OButton from '../../styled/button';
import { Wrapper } from '../../styled/wrappers';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import PaymentModel from '../../../models/payments';

// Locales
import I18n from '../../../i18n';

class Stages extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            sortedStages: [],
            currentStageInfo: {}
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        this.props.openLoader();

        const stages = _.get(await PaymentModel.getStages(), 'data', []);
        const currentStageInfo = _.get(await PaymentModel.getStagesInfo(), 'data', {});
        const sortedStages = _.orderBy(stages, ['stage', 'round', 'phase']);

        this.setState({ currentStageInfo, sortedStages });

        this.props.closeLoader();

    }

    onEdit = stage => {

        window.localStorage.setItem('stageSelected', JSON.stringify(stage));
        this.props.history.push('/stages/edit');

    }

    isDisabled = stage =>
        stage.stage < this.state.currentStageInfo.stage ||
        (stage.stage === this.state.currentStageInfo.stage &&
            (stage.round < this.state.currentStageInfo.round ||
            (stage.round === this.state.currentStageInfo.round && stage.phase <= this.state.currentStageInfo.phase)));

    isCurrentStage = stage => stage.stage === this.state.currentStageInfo.stage && stage.round === this.state.currentStageInfo.round && stage.phase === this.state.currentStageInfo.phase;

    render() {

        const { sortedStages, currentStageInfo } = this.state;

        return (
            <Wrapper>
                <PanelNav active="stages" />
                <Container>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name='bar chart' />
                            { I18n.t('stages.currentInfo') }
                        </Header>
                    </Divider>
                    <Grid columns={ 2 } divided padded>
                        <Grid.Row>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.stage') }: </b>{ currentStageInfo.stage }
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.round') }: </b>{ currentStageInfo.round }
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.phase') }: </b>{ currentStageInfo.phase }
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.price') }: </b>{ currentStageInfo.price }€/YGB
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.tokenAmount') }: </b>
                                            <NumericFormat value={ currentStageInfo.tokenAmount } type="token" />
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.stageTotalTokensSold') }: </b>
                                            <NumericFormat value={ currentStageInfo.stageTotalTokensSold } type="token" />
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.totalTokensSold') }: </b>
                                            <NumericFormat value={ currentStageInfo.totalTokensSold } type="token" />
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.totalAffiliationToken') }: </b>
                                            <NumericFormat value={ currentStageInfo.totalAffiliationToken } type="token" />
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <b>{ I18n.t('stages.outstandingToken') }: </b>
                                            <NumericFormat value={ currentStageInfo.outstandingToken } type="token" />
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name='table' />
                            { I18n.t('stages.list') }
                        </Header>
                    </Divider>
                    <OButton upper type="button" color="#FFF" terciary floated='right' onClick={ () => this.onEdit({}) }>
                        <span>{ I18n.t('buttons.actions.new') }</span>
                    </OButton>
                    <Table style={{ marginTop: '60px' }} singleLine striped color='teal' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='right'>{ I18n.t('stages.stage') }</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>{ I18n.t('stages.round') }</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>{ I18n.t('stages.phase') }</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>{ I18n.t('stages.tokenAmount') }</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>{ I18n.t('stages.price') }</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { sortedStages.map(stage => (
                                <Table.Row key={ stage._id } disabled={ this.isDisabled(stage) } onClick={ () => this.onEdit(stage) }>
                                    <Table.Cell textAlign='left'>
                                        { this.isCurrentStage(stage) ? <Label ribbon>{ I18n.t('stages.current') }</Label> : '' }
                                        <p style={{ textAlign: 'right' }}>{ stage.stage }</p>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        { stage.round }
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        { stage.phase }
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <NumericFormat value={ stage.tokenAmount } type="token" />
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        { stage.price }€
                                    </Table.Cell>
                                </Table.Row>
                            )) }
                        </Table.Body>
                    </Table>
                </Container>
                <BottomNav active="stages" />
            </Wrapper>
        );

    }

}

export default connect(null, {  openLoader, closeLoader })(Stages);