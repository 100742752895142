import React from 'react'
import { Icon } from 'semantic-ui-react';

import * as S from './styled'

import I18n from '../../../i18n';

export default function PanelNav({active, innerNavItems = {} }) {

    return (
        <S.Nav>
            <S.NavContent $justify="flex-end">
                {/* SOLO DESKTOP */}
                <S.NavList>
                <S.ListItem>
                        <S.NavLink activeLink={ active === 'users' } to="/users">{ I18n.t('navigation.users') }</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={ active === 'stages' } to="/stages">{ I18n.t('navigation.stages') }</S.NavLink>
                    </S.ListItem>
                </S.NavList>
                {/* SOLO DESKTOP */}
                <S.ProfileLink to="/?logout=true" >
                    <Icon name="log out"/>
                </S.ProfileLink>
                {/* SOLO MOBILE */}
                <S.InnerNav>
                    { innerNavItems?.render && innerNavItems.render() }
                </S.InnerNav>
                <S.ProfileButton as={ S.NavLink } to="/?logout=true">
                    <div>
                        <Icon name="log out"/>
                    </div>
                </S.ProfileButton>
            </S.NavContent>
        </S.Nav>
    );

}