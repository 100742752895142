module.exports = {
    debugMode: true,
    server: {
        api: {
            paymentApi: process.env.REACT_APP_PAYMENT_API || 'https://payments-dev.yogabot.app/payment/',
            securityApi: process.env.REACT_APP_SECURITY_API || 'https://security-dev.yogabot.app/security/',
            timeout: 20000,
            headTimeout: 2000
        }
    },
    stripe: { publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51J2ZOGFLeUd9hSKZi7YEulCv1RGvXAwosYDAHu8tu9Ns0b6G7lVWgRi3ZbeecI1bjeUDPGDukm5ss9Y169T7sYq000qeHqsmz4' },
    paypal: { clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || 'AW-odrm1g-wVN_8IvUvqB5njaTGm4Q2Z6icSKcLeMANYvkzgCOZVHac2jMdT_8C7CTBNoDNEVU2f1uvO' }
};