import I18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_es from './config/locales/es-ES.json';

I18n
    .use(LanguageDetector)
    .init({
        fallbackLng: 'es',
        whitelist: ['es'],
        lng: 'es',
        resources: { es: { common: common_es } },
        defaultNS: 'common'
    });

export default I18n;