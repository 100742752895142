
import React from 'react';
import _ from 'lodash';
import { FrameClose, FrameModal, ModalContent, Actions, Content } from '../../styled';
import { Icon, Form } from 'semantic-ui-react';
import OButton from '../../../../styled/button';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Select from '../../../form/select/Select';
import CustomInput from '../../../form/input';
import { required } from '../../../../../config/validations';
import I18n from '../../../../../i18n';

/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @param subtitle String
 * @param description String 
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */

 const optionsConcept = [
    {
        key:1,
        text: I18n.t('users.team'),
        value: "team",
    },
    {
        key:2,
        text: I18n.t('users.prize'),
        value: "prize",
    },
    {
        key:3,
        text: I18n.t('users.preico'),
        value: "preico",
    }
]

function WithFormModal({title = {}, buttons = [], scrolling = false, onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
               <Form name="modalForm" noValidate onSubmit={restProps.handleSubmit(restProps.mainAction)}>
                   <Content>
                   {!_.isEmpty(title) && <p className={title.classes.join(" ")} >{title.text}</p>}
                        <Field
                            component={ Select }
                            placeholder={ I18n.t('users.description') }
                            name="concept"
                            options={optionsConcept}
                            fieldClasses="label-primary"
                            label={ I18n.t('users.description') }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.tokenAmount') }
                            name="amount"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.tokenAmount') }
                            restrictions={[{ numeric: true }]}
                            validate={[required]} />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('users.concept') }
                            name="observations"
                            fieldClasses="label-primary"
                            label={ I18n.t('users.concept') }
                            />
                    </Content>
                    <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton key={index} {...options} onClick={ callback }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
            </Form>
            </ModalContent>
        </FrameModal>
    )

}

export default reduxForm({
    form: 'modalForm',
    touchOnBlur: true,
    touchOnChange: false,
})(connect(null, { })(WithFormModal));