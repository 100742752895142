//libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import I18n from '../../../i18n';

const handleColorType = variant => {

    switch (variant) {

        case "correct":

            return "var(--brand-positive)";

        case "incorrect":

            return "var(--red-color-secondary)";

        default:

            return "var(--brand-positive)";

    }

};

const MessagerBox = styled.div`
    display: block;
    color: white;
    font-family: PoppinsRegular;
    font-size: 14px;
    padding: .5833em .833em;
    border-radius: .28571429rem;
    margin-top: 15px;
    margin-bottom: 15px;
    a{
        color: white!important;
        text-decoration: underline;
    }

    background: ${({ $variant }) => handleColorType($variant)};
`;

const Messager = (props) => {

    const [message, setMessage] = useState('');

    useEffect (()=> {

        switch (props.code) {

            case 'error-login':

                setMessage(<p>{ I18n.t('messager.errorLogin') }</p>);
                break;

            case 'error-recovery':

                setMessage(<p>{ I18n.t('messager.errorRecovery') }</p>);
                break;

            default:

                setMessage(<p>{ I18n.t(`messager.errorWithoutCode`) }</p>)

        }

    }, [props.code]);

    return props.active && (
        <MessagerBox $variant={ props.$variant }>
            { message }
        </MessagerBox>
    );

}

export default Messager;