import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import * as S from './styled';

function BottomNav({ active }) {

    return (
        <S.BottomNav role="tablist" >
            <S.BottomNavItem role="tab" $active={ active === 'users' } as={ Link } to="/users">
                <Icon name="users" />
            </S.BottomNavItem>
            <S.BottomNavItem role="tab" $active={ active === 'stages' } as={ Link } to="/stages">
                <Icon name="clipboard list" />
            </S.BottomNavItem>
        </S.BottomNav>
    );

}

export default BottomNav;