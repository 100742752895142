import { useEffect, useRef } from 'react';

import SecurityModel from '../../../models/security';

const KeepAlive = () => {

    const interval = useRef();

    const doCheck = async () => {

        try {

            const response = await SecurityModel.checkJwt();

            window.localStorage.setItem('jwt', response.data.jwt);

        } catch (error) {

            console.error('error:KeepAlive')

        }

    }

    useEffect(() => {

        interval.current = setInterval(() => {

            if (window.localStorage.getItem('jwt')) {

                doCheck();

            }

        }, 1000 * 60 * 30);

        return () => clearInterval(interval.current);

    }, []);

    return null;

}

export default KeepAlive;