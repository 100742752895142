// libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { Container, Form } from 'semantic-ui-react';

// components
import BottomNav from '../../../components/bottomNav/BottomNav';
import CustomInput from '../../../components/form/input';
import PanelNav from '../../../components/nav/PanelNav';

// styled
import OButton from '../../../styled/button';
import { FlexBox, Wrapper } from '../../../styled/wrappers';

// redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openToast } from '../../../../redux-store/toast';

// models
import PaymentModel from '../../../../models/payments';

// locales
import I18n from '../../../../i18n';

import { required } from '../../../../config/validations'

class StageEdit extends React.Component {

    componentDidMount() {

        const initialValues = _.merge({
            phase: null,
            price: null,
            round: null,
            stage: null,
            tokenAmount: null
        }, JSON.parse(window.localStorage.getItem('stageSelected')));

        this.props.dispatch(initialize(
            'stageEditForm',
            initialValues
        ))

    }

    onSubmit = async formData => {

        try {

            if (formData._id) {

                await PaymentModel.updateStage({ ...formData });

            } else {

                await PaymentModel.createStage({ ...formData });

            }

            openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });
            this.onGoBack();

        } catch (codeError) {

            openToast({ message: I18n.t(`status.code.${codeError}`), type: 'error' });

        }

    }

    onGoBack = () => {

        window.localStorage.removeItem('stageSelected');
        this.props.history.push('/stages');

    }

    render() {

        return (
            <Wrapper>
                <PanelNav active="stages" />
                <Container style={{ paddingTop: '20px' }}>
                    <Form name="stageEditForm" noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) }>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.stage') }
                            name="stage"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.stage') }
                            restrictions={[{ numeric: true }]}
                            validate={[required]} />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.round') }
                            name="round"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.round') }
                            restrictions={[{ numeric: true }]}
                            validate={[required]} />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.phase') }
                            name="phase"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.phase') }
                            restrictions={[{ numeric: true }]}
                            validate={[required]} />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.price') }
                            name="price"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.price') }
                            restrictions={[{ numeric: true, canBeFloat: true }]}
                            validate={[required]} />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('stages.tokenAmount') }
                            name="tokenAmount"
                            fieldClasses="label-primary"
                            label={ I18n.t('stages.tokenAmount') }
                            restrictions={[{ numeric: true }]}
                            validate={[required]} />
                        <FlexBox $justifyContent='flex-end'>
                            <OButton upper type="button" color="#1fd5b9" selected onClick={ this.onGoBack }>
                                <span>{ I18n.t('buttons.actions.cancel') }</span>
                            </OButton>
                            <OButton upper type="submit" color="#FFF" terciary disabled={ this.props.invalid }>
                                <span>{ I18n.t('buttons.actions.saveChanges') }</span>
                            </OButton>
                        </FlexBox>
                    </Form>
                </Container>
                <BottomNav active="stages" />
            </Wrapper>
        );

    }

}

export default reduxForm({
    form: 'stageEditForm',
    touchOnBlur: true,
    touchOnChange: false
})(connect(null, {  openLoader, closeLoader })(StageEdit));