import _ from 'lodash';
import AxiosService from '../../utils/AxiosService';
import Config from '../../config';

const convertNumberFields = stageObject => {

    const { phase, price, round, stage, tokenAmount } = stageObject;
    return _.merge(stageObject, {
        phase: parseInt(phase),
        price: parseFloat(price),
        round: parseInt(round),
        stage: parseInt(stage),
        tokenAmount: parseInt(tokenAmount)
    });

}

// STAGES
const createStage = async stage => await AxiosService.post(Config.server.api.paymentApi + 'io/admin/stages', convertNumberFields(stage), true)
const getStages = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/admin/stages', true);
const getStagesInfo = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/stages', false);
const updateStage = async stage => await AxiosService.put(Config.server.api.paymentApi + 'io/admin/stages/' + stage._id, convertNumberFields(_.omit(stage, ['_id'])), true)

// USER
const getUserBalance = async userId => await AxiosService.get(Config.server.api.paymentApi + `io/admin/user/${ userId }/balance`, true);
const getAffiliateCommissions = async userId => await AxiosService.get(Config.server.api.paymentApi + `io/admin/commissions/user/${ userId }`, true);
const createUserAsignment = async (userId, payload) => await AxiosService.post(Config.server.api.paymentApi + `io/admin/user/${ userId }/tokens`, {
    amount:parseInt(payload.amount),
    concept: payload.concept,
    observations: payload.observations || ''
}, true);
const getUserAsignments = async userId => await AxiosService.get(Config.server.api.paymentApi + `io/admin/user/${ userId }/tokens`, true);

export default {
    createStage,
    getStages,
    getStagesInfo,
    getUserBalance,
    getAffiliateCommissions,
    updateStage,
    createUserAsignment,
    getUserAsignments
};