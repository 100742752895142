// libraries & components libraries
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import { ToastContainer } from 'react-toastify';

// components
import Loader from './components/loader';
import GenericModal from './components/genericModal';

// routes
import Login from './routes/auth/login/Login';
import Stages from './routes/stages/Stages';
import StageEdit from './routes/stages/edit/Edit';
import Users from './routes/users/Users';
import UserDetails from './routes/users/details/Details';

// global styles
import GlobalStyle from './styled/global';

// redux
import { openLoader, closeLoader } from '../redux-store/loader';
import { getProfile } from '../redux-store/auth';

// locales
import I18n from '../i18n';
import UserNew from './routes/users/new/UserNew';

const PrivateRoute = ({ ...props }) => window.localStorage.getItem('jwt') ? <Route { ...props } /> : <Redirect to="/auth/login" />;

class App extends Component {

    componentDidMount() {

        this.initialize();

    }

    initialize = async () => {

        const { openLoader, closeLoader, getProfile } = this.props;

        try {

            openLoader();

            const exp = window.localStorage.getItem('jwt') ? JSON.parse(atob(window.localStorage.getItem('jwt').split('.')[1])).exp : 0;

            if (Moment(exp * 1000).diff(Moment(), 'minutes') > 5) {

                await getProfile();

            } else {

                window.localStorage.removeItem('jwt');

            }

        } catch (codeError) {

            console.error('App: codeError', codeError)

        } finally {

            closeLoader();

        }

    }

    render() {

        return (
            <I18nextProvider i18n={ I18n }>
                <HashRouter>
                    <Switch>
                        <PrivateRoute path="/stages" exact component={ Stages } />
                        <PrivateRoute path="/stages/edit" exact component={ StageEdit } />
                        <PrivateRoute path="/users" exact component={ Users } />
                        <PrivateRoute path="/users/edit" exact component={ UserDetails } />
                        <PrivateRoute path="/users/new" exact component={ UserNew } />
                        <Route path="/auth/login" exact component={ Login } />
                        <Route path="/" exact component={ Login } />
                        <Route exact path="/">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </HashRouter>
                <GlobalStyle />
                <Loader />
                <GenericModal />
                <ToastContainer autoClose={ 2000 } />
            </I18nextProvider>
        );

    }

}

export default connect(null, { openLoader, closeLoader, getProfile })(App);
