// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { Container, Form, Segment, Divider, Card, Icon } from 'semantic-ui-react';
import Moment from 'moment';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import PanelNav from '../../../components/nav/PanelNav';
import NumericFormat from '../../../components/numericFormat/NumericFormat';
import CustomCheckBox from '../../../components/form/checkbox';

// Styled components
import OButton from '../../../styled/button';
import { FlexBox, Wrapper } from '../../../styled/wrappers';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { openToast } from '../../../../redux-store/toast';

// Models
import PaymentModel from '../../../../models/payments';
import SecurityModel from '../../../../models/security';

// Locales
import I18n from '../../../../i18n';

class UserDetails extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            affiliation: 0,
            balance: 0,
            preico: 0,
            user: {},
            stagesInfo: {},
            affStats: {},
            investment: 0,
            teamTokens: 0,
            prizeTokens: 0
        };

    }

    componentDidMount() {

        this.getInfos();

    }

    componentWillUnmount() {

        this.onGoBack();

    }

    getInfos = async () => {

        const { openLoader, closeLoader } = this.props;

        const user = JSON.parse(window.localStorage.getItem('userSelected'));

        const initialValues = {
            adminOn: user?.role?.includes('ygb-admin'),
            affiliateOn: user?.affiliateOn || false
        };

        this.props.dispatch(initialize('userEditForm', initialValues));

        try {

            openLoader();

            const stages = await PaymentModel.getStagesInfo();
            const balanceData = _.get(await PaymentModel.getUserBalance(user._id), 'data', {});
            const { affiliation, balance, investment, preico } = balanceData;

            const commissions = await PaymentModel.getAffiliateCommissions(user._id);
            const userAsignments = await PaymentModel.getUserAsignments(user._id);

            const teamTokens = _.chain(userAsignments.data).filter(({ concept }) => concept === 'team').map(({ amount }) => ({ tokens: amount })).sumBy('tokens').value();
            const prizeTokens = _.chain(userAsignments.data).filter(({ concept }) => concept === 'prize').map(({ amount }) => ({ tokens: amount })).sumBy('tokens').value();

            let affStats = _.reduce(commissions.data, (acc, el) => ({
                ...acc,
                [el.concept]: acc[el.concept] ? (acc[el.concept] + el.amount) : el.amount
            }), {});

            this.setState({ stagesInfo: stages.data, affiliation, balance, investment, preico, teamTokens, prizeTokens, user, affStats });

        } catch (error) {

            console.log('Error loading data', error);

        } finally {

            closeLoader();

        }

    }

    onGoBack = () => window.localStorage.removeItem('userSelected');

    onSubmit = values => {

        const { openGenericModal, closeGenericModal } = this.props;
        const { user } = this.state;

        if (values.adminOn && !user.role.includes('ygb-admin')) {

            openGenericModal({
                type: 'simple',
                title: {
                    text: I18n.t('popups.info'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t('popups.confirmSaveAdmin'),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('buttons.actions.understood'),
                    callback: () => {

                        closeGenericModal();
                        this.onSaveGrants(user._id, values);

                    },
                    options: {
                        terciary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                },
                {
                    text: I18n.t('buttons.actions.cancel'),
                    callback: closeGenericModal,
                    options: {
                        selected: true,
                        color: '#1fd5b9',
                        fluid: true,
                        upper: true,
                        style: {marginTop: '10px'}
                    }
                }]
            });

        } else {

            this.onSaveGrants(user._id, values);

        }

    }

    onSaveGrants = async (userId, payload) => {

        const { openLoader, closeLoader } = this.props;
        let hasError = false;

        try {

            openLoader();
            await SecurityModel.updateGrants(userId, { ...payload });

        } catch (error) {

            hasError = true;

        } finally {

            closeLoader();

            if (hasError) {

                openToast({ message: I18n.t(`messages.errorSaving`), type: 'error' });

            } else {

                openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });
                this.props.history.push('/users');

            }

        }

    }

    openPopup = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'withForm',
            title: {
                text: I18n.t('users.tokens'),
                classes: ['heading-2']
            },
            mainAction: values => {

                this.onCreateAssignments(values);
                closeGenericModal();

            },
            buttons: [{
                text: I18n.t('buttons.actions.saveChanges'),
                callback: () => { },
                options: {
                    terciary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true,
                    type: "submit"
                }
            },
            {
                text: I18n.t('buttons.actions.cancel'),
                callback: closeGenericModal,
                options: {
                    selected: true,
                    color: '#1fd5b9',
                    fluid: true,
                    upper: true,
                    style: { marginTop: '10px' },
                    type: "button"
                }
            }]
        });

    }

    onCreateAssignments = async values => {

        const { openLoader, closeLoader } = this.props;
        const { user } = this.state;
        let hasError = false;

        try {

            openLoader();
            await PaymentModel.createUserAsignment(user._id, values);

        } catch (error) {

            hasError = true;

        } finally {

            closeLoader();

            if (hasError) {

                openToast({ message: I18n.t(`messages.errorSaving`), type: 'error' });

            } else {

                openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });
                this.getInfos();

            }

        }

    }

    render() {

        const { affStats, balance, user, affiliation, teamTokens, prizeTokens, investment, preico, stagesInfo: { price = 0 } } = this.state;

        return (
            <Wrapper>
                <PanelNav active="users" />
                <Container style={{ padding: '20px 0' }}>
                    <FlexBox $alignItems="center" onClick={ () => this.props.history.push('/users') }>
                        <Icon name="arrow left" className="brand c-pointer" style={{ height: 'auto' }}/>
                        <span className="labeling regular capitalCase c-pointer">{ I18n.t('buttons.actions.goBack') }</span>
                    </FlexBox>
                    <Segment>
                        <h1 className="heading-3">{ I18n.t('users.details') }</h1>
                        <Divider />
                        { user?.name && <p className="labeling">{ I18n.t('users.name') }: <span className="labeling regular">{ user.name }</span></p> }
                        { user?.surname && <p className="labeling">{ I18n.t('users.surname') }: <span className="labeling regular">{ user.surname }</span></p> }
                        <p className="labeling">{ I18n.t('users.email') }: <span className="labeling regular">{ user.email }</span></p>
                        <p className="labeling">{ I18n.t('users.emailVerified') }: <span className="labeling regular">{ I18n.t(user.emailVerified ? 'common.yes' : 'common.no') }</span></p>
                        <p className="labeling">{ I18n.t('users.createdAt') }: <span className="labeling regular">{ Moment(user.createdAt).format('DD/MM/YYYY - HH:mm') }</span></p>
                        { user.stellarPublicKey && <p className="labeling">{ I18n.t('users.stellarPK') } : <span className="labeling regular">{ user.stellarPublicKey }</span></p> }
                    </Segment>
                    <Segment>
                        <h1 className="heading-3">{ I18n.t('users.grants') }</h1>
                            <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name="userEditForm">
                            <FlexBox $justifyContent="space-between" $alignItems="center">
                                <div>
                                <Form.Field>
                                    <Field
                                        component={ CustomCheckBox }
                                        name="adminOn"
                                        label={ I18n.t('users.isAdmin') }
                                        toggle
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Field
                                        component={ CustomCheckBox }
                                        name="affiliateOn"
                                        label={ I18n.t('users.allowRefLink') }
                                        toggle
                                    />
                                </Form.Field>
                            </div>
                                <OButton upper type="submit" color="#FFF" terciary >
                                    <span>{ I18n.t('buttons.actions.saveChanges') }</span>
                                </OButton>
                            </FlexBox>
                        </Form>
                    </Segment>
                    <Segment>
                        <FlexBox $justifyContent='space-between' $alignItems="center">
                          <h1 className="heading-3" style={{ margin: '0' }}>{ I18n.t('users.tokens') }</h1>
                            <OButton upper type="button" color="#FFF" terciary onClick={ this.openPopup }>
                                <span>{ I18n.t('buttons.actions.createAssignment') }</span>
                            </OButton>
                        </FlexBox>
                        <Divider />
                        <p className="labeling ">{ I18n.t('users.balance') }: <span className="labeling regular"><NumericFormat value={ balance } type="token" /> / <NumericFormat value={ balance * price } type="eur" /></span></p>
                        <p className="labeling ">{ I18n.t('users.preicoTokens') }: <span className="labeling regular"><NumericFormat value={ preico } type="token" /> / <NumericFormat value={ preico * price } type="eur" /></span></p>
                        <p className="labeling ">{ I18n.t('users.boughtTokens') }: <span className="labeling regular"><NumericFormat value={ investment } type="token" /> / <NumericFormat value={ investment * price } type="eur" /></span></p>
                        <p className="labeling ">{ I18n.t('users.gainTokens') }: <span className="labeling regular"><NumericFormat value={ affiliation } type="token" /> / <NumericFormat value={ affiliation * price } type="eur" /></span></p>
                        <p className="labeling ">{ I18n.t('users.gainTokensTeam') }: <span className="labeling regular"><NumericFormat value={ teamTokens } type="token" /> / <NumericFormat value={ teamTokens * price } type="eur" /></span></p>
                        <p className="labeling ">{ I18n.t('users.gainTokensPrize') }: <span className="labeling regular"><NumericFormat value={ prizeTokens } type="token" /> / <NumericFormat value={ prizeTokens * price } type="eur" /></span></p>
                    </Segment>
                    <Segment>
                        <h1 className="heading-3">{ I18n.t('users.affiliates') }</h1>
                        <Divider />
                        <Card.Group>
                            <Card color='teal'>
                                <Card.Content>
                                    <Card.Header><p className="paragraph regular">{ I18n.t('users.affiliatesCards.c1.text') }</p></Card.Header>
                                    <Card.Description>
                                        <p className="heading-2">{ affStats['ygb-affiliate-click'] || 0 }</p>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                            <Card color='teal'>
                                <Card.Content>
                                    <Card.Header><p className="paragraph regular">{ I18n.t('users.affiliatesCards.c2.text') }</p></Card.Header>
                                    <Card.Description>
                                    <p className="heading-2">{ affStats['ygb-affiliate-register'] || 0 }</p>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                            <Card color='teal'>
                                <Card.Content>
                                    <Card.Header><p className="paragraph regular">{ I18n.t('users.affiliatesCards.c3.text') }</p></Card.Header>
                                    <Card.Description>
                                        <p className="heading-2">{ <NumericFormat value={ (affStats['ygb-affiliate-first-buy']  || 0) * 5 } type="token" /> }</p>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                            <Card color='teal'>
                                <Card.Content>
                                    <Card.Header><p className="paragraph regular">{ I18n.t('users.affiliatesCards.c4.text') }</p></Card.Header>
                                    <Card.Description>
                                        <p className="heading-2">{ <NumericFormat value={ (affStats['ygb-affiliate-first-buy'] || 0) } type="token" /> }</p>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Segment>
                </Container>
                <BottomNav active="users" />
            </Wrapper>
        );

    }

}

export default reduxForm({
    form: 'userEditForm',
    touchOnBlur: true,
    touchOnChange: false
})(connect(null, {  openLoader, closeLoader, openGenericModal, closeGenericModal })(UserDetails));