// libraries & components libraries
import _ from 'lodash';
import React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Container, Grid, Icon, List, Table, Pagination, Menu, Input, Form, Segment } from 'semantic-ui-react';

//components
import PanelNav from '../../components/nav/PanelNav';

//styled
import OButton from '../../styled/button';
import { FlexBox, Wrapper } from '../../styled/wrappers'

//redux
import { closeLoader, openLoader } from '../../../redux-store/loader';
import BottomNav from '../../components/bottomNav/BottomNav';

// models
import SecurityModel from '../../../models/security';

// locales
import I18n from '../../../i18n';

//TODO: Listado usuarios PRE-ICO
//TODO: Listado usuarios ICO
//TODO: Acción crear usuario ICO
//TODO: Visualizción usuario concreto: inversiones, pre-ico, afiliaciones, asignaciones de tokens(concepto: pre-ico, inversión externa, equipo, premio), roles
//TODO: Acción de enviar email con instrucciones de reseteo de clave (le mandamos clave inicial)

class Users extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            direction: 'descending',
            filteredUsers: [],
            sortBy: 'createdAt',
            users: [],
            filterValue: "",
            userStats: {},
            pageNumber: 1,
            pageSize: 0,
            recordsPerPage: 25,
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        this.props.openLoader();

        const users = _.get(await SecurityModel.getUsers(), 'data', []);
        const filteredUsers = _.orderBy(users, this.state.sortBy, 'desc');

        const userStats = {
            areAdmin: _.size(_.filter(users, user => user.role.includes('admin'))),
            count: _.size(users),
            emailVerified: _.size(_.filter(users, user => user.emailVerified)),
            removed: _.size(_.filter(users, user => !user.activeAccount))
        };

        this.setState({ filteredUsers, users, userStats });

        this.props.closeLoader();

    };

    onEdit = user => {

        window.localStorage.setItem('userSelected', JSON.stringify(user));
        this.props.history.push('/users/edit');

    };

    onSort = sortBy => this.setState({
        sortBy,
        direction: sortBy !== this.state.sortBy || this.state.direction === 'ascending' ? 'descending' : 'ascending',
        filteredUsers: _.orderBy(this.state.filteredUsers, sortBy, sortBy !== this.state.sortBy || this.state.direction === 'ascending' ? 'desc' : 'asc')
    });

    onFilter = filterValue => {

        let filteredUsers = [...this.state.users];

        if (filterValue) {

            filteredUsers = _.filter(filteredUsers, user => user?.email?.includes(filterValue) || user?.name?.includes(filterValue) || user?.surname?.includes(filterValue))

        }

        this.setState({filteredUsers, pageSize: Math.ceil(filteredUsers.length / this.state.recordsPerPage), pageNumber: 1, filterValue})
    
    }

    paginate = data => data.slice((this.state.pageNumber - 1) * this.state.recordsPerPage, this.state.pageNumber * this.state.recordsPerPage);

    onPageChange = (e, pageInfo) => this.setState({ pageNumber: pageInfo.activePage });

    isDisabled = user => !user.activeAccount;

    render() {

        const { sortBy, direction, filteredUsers, userStats, filterValue } = this.state;

        return (
            <Wrapper>
                <PanelNav active="users" />
                <Container>
                    <p className="heading-3" style={{marginTop: '30px'}}>{I18n.t('navigation.users')}</p>
                    <Segment  >
                        <p className="paragraph"><Icon name='bar chart' /> {I18n.t('users.currentInfo')}</p>
                        <Grid columns={ 2 } divided padded>
                            <Grid.Row>
                                <Grid.Column>
                                    <List>
                                        <List.Item>
                                            <List.Content>
                                            <p className="labeling regular"><span className="labeling">{ I18n.t('users.count') }</span>: { userStats.count }</p>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                            <p className="labeling regular"><span className="labeling">{ I18n.t('users.removed') }</span>: { userStats.removed }</p>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List>
                                        <List.Item>
                                            <List.Content>
                                                <p className="labeling regular"><span className="labeling">{ I18n.t('users.emailVerified') }</span>: { userStats.emailVerified }</p>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <p className="labeling regular"><span className="labeling">{ I18n.t('users.areAdmin') }</span>: { userStats.areAdmin }</p>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment >
                    <p className="paragraph"><Icon name='table' /> {I18n.t('users.list')}</p>
                        <FlexBox $justifyContent="space-between">
                            <div style={{width: '255px'}}>
                                <Form>
                                    <Form.Field >
                                        <Input 
                                            placeholder="Buscar por nombre, apellido o email" 
                                            type="text" 
                                            value={filterValue} 
                                            onChange={(e, { value }) => this.onFilter(value) } 
                                        />
                                    </Form.Field>
                                </Form>
                            </div>
                            <OButton upper type="button" color="#FFF" terciary onClick={() => this.props.history.push('/users/new')}>
                                <span>{ I18n.t('buttons.actions.new') }</span>
                            </OButton>
                        </FlexBox>
                        <Table style={{ marginTop: '20px' }} singleLine striped sortable color='teal' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={ sortBy === 'email' ? direction : null } onClick={ () => this.onSort('email') }>{ I18n.t('users.email') }</Table.HeaderCell>
                                    <Table.HeaderCell sorted={ sortBy === 'name' ? direction : null } onClick={ () => this.onSort('name') }>{ I18n.t('users.name') }</Table.HeaderCell>
                                    <Table.HeaderCell sorted={ sortBy === 'surname' ? direction : null } onClick={ () => this.onSort('surname') }>{ I18n.t('users.surname') }</Table.HeaderCell>
                                    <Table.HeaderCell sorted={ sortBy === 'createdAt' ? direction : null } onClick={ () => this.onSort('createdAt') }>{ I18n.t('users.createdAt') }</Table.HeaderCell>
                                    <Table.HeaderCell sorted={ sortBy === 'emailVerified' ? direction : null } onClick={ () => this.onSort('emailVerified') }>{ I18n.t('users.emailVerified') }</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.paginate(filteredUsers).map(user => (
                                        <Table.Row key={ user._id } disabled={ !user.activeAccount } onClick={ () => this.onEdit(user) }>
                                            <Table.Cell>
                                                { user.email }
                                            </Table.Cell>
                                            <Table.Cell>
                                                { user.name }
                                            </Table.Cell>
                                            <Table.Cell>
                                                { user.surname }
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                { Moment(user.createdAt).format('DD/MM/YYYY - HH:mm') }
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                { I18n.t(user.emailVerified ? 'common.yes' : 'common.no') }
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={ 8 }>
                                        <Menu floated='right'>
                                            <Pagination
                                                onPageChange={ this.onPageChange }
                                                boundaryRange={ 1 }
                                                defaultActivePage={ 1 }
                                                prevItem={ null }
                                                nextItem={ null }
                                                siblingRange={ 2 }
                                                totalPages={ Math.ceil(filteredUsers.length / this.state.recordsPerPage) }
                                            />
                                        </Menu>
                                        <div style={{ clear: "both" }}></div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Segment>
                </Container>
                <BottomNav active="users" />
            </Wrapper>
        );

    }

}

export default connect(null, {  openLoader, closeLoader })(Users);