import AxiosService from '../../utils/AxiosService';

import Config from '../../config';

const checkJwt = async () => await AxiosService.get(Config.server.api.securityApi + 'io/admin/check', true);
const getProfile = async () => await AxiosService.get(Config.server.api.securityApi + 'io/getprofile', true);
const getUsers = async () => await AxiosService.get(Config.server.api.securityApi + 'io/admin/user', true);
const login = async payload => await AxiosService.post(Config.server.api.securityApi + 'io/admin/login', payload, false);
const updateGrants = async (userId, payload) => await AxiosService.put(Config.server.api.securityApi + `io/admin/user/${userId}/grants`, payload, true);

const editUser = async payload => await AxiosService.post(Config.server.api.securityApi + `io/admin/user`, payload, true);

const exports = {
    checkJwt,
    getProfile,
    getUsers,
    login,
    updateGrants,
    editUser
};

export default exports;