import React from 'react';
import { Link } from 'react-router-dom';
import styled, {css} from 'styled-components/macro';
import { device } from '../../../styled/mbreakpoints';

const Nav = styled.nav`

    background-color:var(--brand-primary);
    padding: 1.42rem 1.5rem;
    position: sticky;
    top: 0px;
    z-index:10;
    ${({$active}) => $active &&

    css`
        @media (max-width: 767px) {

            ${ToogleButtonBar} {

                &.bar-1 {
                    transform: translate(0, 0.6rem) rotate(45deg);
                    background-color: white;
                }

                &.bar-2 {
                    opacity:0;
                }

                &.bar-3 {
                    transform: translate(0, -0.6rem) rotate(-45deg);
                    background-color: white;
                }
            }

        }
    `};
`;

const Drawer = styled.div`

    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 0px;
    padding: 5rem 4rem 6rem;
    background-color: rgba(37, 43, 70, 0.95);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: all 0s ease 1s, opacity 1s ease 0s;
    overflow: auto;
    pointer-events: none;

        ${({$active}) => $active && css`
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100vh;
                opacity: 1;
                transition: opacity 0.5s ease 0s;
                pointer-events: initial;
            }
        `};
    
`;

const NavContent = styled.div`
    max-width:var(--max-width-container);
    width:100%;
    margin:0 auto;
    display:flex;
    justify-content: ${({$justify}) => $justify ? $justify : 'space-between'} ;
`;

const NavList = styled.ul`

    display:none;
    padding:0;
    list-style: none;
    text-align: center;
    align-items: center;
    @media ${ device.tablet } { 
        display:flex;
    }
`;

const DropdownList = styled.ul`
    list-style: none;
    text-align: left;
`;

const DropdownItem = styled.li`

    margin-bottom:1rem;
    border-bottom: 1px solid #fff;
    padding:1rem 0;
    ${({$inner}) => $inner && css`
        border-bottom: none;
        margin:1rem 0;
        padding: 0.5rem 0 0 2rem;
    `};
`;

const ListItem = styled.li`
    position:relative;
    & + & {
        margin-left:1.4rem;
    }
`;

const ButtonAsLink = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: white;
    font-size: 1rem;
    transition: color .3s;
    font-weight: 400;
    text-decoration: none;
    font-family: PoppinsRegular;
    letter-spacing: 1.5px;
    &:hover, &:focus {
        color:var(--brand-secondary);
    }
   
`;


const NavLink = styled(({activeLink, $isProfile, ...rest})=> <Link  { ...rest} />)`

    color: white;
    font-size: 1rem;
    letter-spacing: 1.5px;
    text-decoration: none;
    font-family:PoppinsRegular;
    transition: color .3s;
    font-weight:400;

    ${({$isProfile}) => $isProfile && css`
    display: flex;
    align-items: center;
        i {
            
            font-size: 22px;
            height: auto;
            &:before {
                transition: color .3s;
                color: white;
            }
        }

        &:hover, &:focus {
            i { 
                &:before{
                    color:var(--brand-secondary);
                }
                
            }
        }

        ${({activeLink}) => activeLink && css`
            i { 
               &:before{
                    color:var(--brand-secondary);
                }
            }
            &:hover, &:focus {
                i { 
                   &:before{
                    color:var(--brand-secondary);
                }
                }
            }
        `};
    `};

    ${({activeLink}) => activeLink && css`
        color:var(--brand-secondary);
    `};

    &:hover, &:focus {
        color:var(--brand-secondary);
    }

    &.submenu {
        color: var(--brand-primary);
        ${({activeLink}) => activeLink && css`
        color:var(--brand-secondary);
        
    `};
        &:hover, &:focus {
            color:var(--brand-secondary);
        }
    }
       
`;


const InfoContentList = styled.ul`
    list-style: none;
    ${({$submenu}) => !$submenu  ? css`
        display:none;
        ${({$show}) => $show && css`
            display:block;
        `};
    ` : css`
        display:block;
        ${DropdownItem} {
            border-bottom: none;
            margin:0.5rem 0;
            padding:0;
            text-align:left;
        }
    `};
    
`;

const SubMenuModal = styled.div`

    position:absolute;
    padding: 1rem 2rem;
    border-radius:10px;
    z-index:2;
    background:#fff;
    display:none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    top: 120%; 
    ${({$show}) => $show && css`
        display:block;
    `};

`;

const NavButtons = styled.div`
    display:none;
    width: 13rem;
    justify-content: space-between;
    @media ${ device.tablet } { 
        display:flex;
    }
`;

const ToogleButton = styled.button`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 10;
    margin-left:auto;

    @media ${ device.tablet } { 
        display:none;
    }
`;

const ProfileButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    outline: none;
    display:none;
    border:none;
    z-index: 10;
    div {
        display:flex;
        align-items:center;
        justify-content:center;
        i {
            
            font-size: 22px;
            height: auto;
            &:before {
                color: white;
            }
        }
    }
    @media (max-width:767px) { 
        display: inline-block;
    }
`;

const ProfileLink = styled(({activeLink, ...rest})=> <Link  { ...rest} />)`
    display: none;
    align-items: center;
    margin-left:1.2rem;
    i {
            
        font-size: 22px;
        height: auto;
        &:before {
            transition: color .3s;
            color: white;
        }
    }
    ${({activeLink}) => activeLink && css`
        i { 
            &:before{
                color:var(--brand-secondary);
            }
        }
        &:hover, &:focus {
            i { 
                &:before{
                color:var(--brand-secondary);
            }
            }
        }
    `};
    @media ${ device.tablet } { 
        display: flex;
    }
`;

const ToogleButtonBar = styled.span`

    display: inline-block;
    width: 1.35rem;
    height: 0.21rem;
    background-color: #fff;
    transition: all .5s;
    transform-origin:center;
    

`;

const InnerNav = styled.div`
    display:flex;
    justify-content:space-between;
    flex:1;
    @media ${ device.tablet } { 
        display: none;
    }
`;

const BackActionMobile = styled.div`
i {
    &:before{
        color:#fff;
    }
}

`;

export {
    Nav,
    NavContent,
    NavList,
    ListItem,
    NavLink,
    NavButtons,
    ToogleButton,
    ToogleButtonBar,
    ProfileLink,
    Drawer,
    DropdownList,
    DropdownItem,
    ButtonAsLink,
    InfoContentList,
    SubMenuModal,
    ProfileButton,
    InnerNav,
    BackActionMobile
}
