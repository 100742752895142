// libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Container, Form, Segment, Icon } from 'semantic-ui-react';

// components
import BottomNav from '../../../components/bottomNav/BottomNav';
import PanelNav from '../../../components/nav/PanelNav';

// styled
import OButton from '../../../styled/button';
import { FlexBox, Wrapper } from '../../../styled/wrappers';

// redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { openToast } from '../../../../redux-store/toast';

// models
import SecurityModel from '../../../../models/security';

// locales
import I18n from '../../../../i18n';

import CustomInput from '../../../components/form/input';


class UserNew extends React.Component {

    onSubmit = async values => {

        let hasError = false;

        try {

            await SecurityModel.editUser(values);
            openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });
            
        } catch (error) {
            
            hasError = true;
            openToast({ message: I18n.t(`messages.errorSaving`), type: 'error' });

        } finally {

            if (!hasError) {

                this.props.history.push('/users');

            }

        }

    }

    goBack = () => this.props.history.push('/users')

    render() {

        return (
            <Wrapper>
                <PanelNav active="users" />
                <Container style={{ padding: '20px 0' }}>
                    <FlexBox $alignItems="center" onClick={this.goBack}>
                        <Icon name="arrow left" className="brand c-pointer" style={{height: 'auto'}}/><span className="labeling regular capitalCase c-pointer">{I18n.t('buttons.actions.goBack')}</span>
                    </FlexBox>
                    <Segment>
                        <h1 className="heading-3">{I18n.t('users.createUser')}</h1>
                        <Form noValidate onSubmit={this.props.handleSubmit(this.onSubmit)} name="userNewForm">
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('users.name') }
                            name="name"
                            fieldClasses="label-primary"
                            label={ I18n.t('users.name') }
                         />
                         <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('users.surname') }
                            name="surname"
                            fieldClasses="label-primary"
                            label={ I18n.t('users.surname') }
                         />
                         <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('users.email') }
                            name="email"
                            fieldClasses="label-primary"
                            label={ I18n.t('users.email') }
                         />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('users.stellarPK') }
                            name="stellarPublicKey"
                            fieldClasses="label-primary"
                            label={ I18n.t('users.stellarPK') }
                         />
                         <FlexBox $justifyContent='flex-end'>
                            <OButton upper type="button" color="#1fd5b9" selected onClick={this.goBack}>
                                <span>{ I18n.t('buttons.actions.cancel') }</span>
                            </OButton>
                            <OButton upper type="submit" color="#FFF" terciary disabled={ this.props.invalid }>
                                <span>{ I18n.t('buttons.actions.saveChanges') }</span>
                            </OButton>
                        </FlexBox>
                        </Form>
                    </Segment>
                </Container>
                <BottomNav active="users" />
            </Wrapper>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');
        
    }

    return errors;

};

export default reduxForm({
    form: 'userNewForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        name:"",
        surname: "",
        email: "",
        stellarPublicKey: ""
    }
})(connect(null, {  openLoader, closeLoader, openGenericModal, closeGenericModal })(UserNew));